/* Email Support Form Styles */
.email-support-modal .ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

[data-theme="light"] .email-support-modal .ant-modal-content {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

.email-support-modal .ant-modal-title {
  color: var(--color-text-dark);
  font-size: 18px;
}

.email-support-modal .ant-modal-body {
  padding: 20px;
  background: var(--color-background-dark);
  /* Remove fixed height to allow content to determine height */
  overflow-y: visible;
}

.email-form-container {
  background-color: var(--color-background);
  color: var(--color-text);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* Remove fixed height and let content flow naturally */
}

[data-theme="dark"] .email-form-container {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

.email-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  color: var(--color-text-dark);
}

.email-header-icon {
  font-size: 20px;
  color: #1a73e8;
}

.email-header h2 {
  margin: 0;
  font-size: 18px;
  color: var(--color-text-dark);
}

[data-theme="light"] .email-header h2 {
  color: var(--color-text);
}

.email-form .form-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

.form-item-half {
  width: 100%;
}

.form-item-full {
  grid-column: 1 / -1;
  width: 100%;
}

.email-form .ant-form-item {
  margin-bottom: 0;
}

.email-form .ant-form-item-label {
  padding-bottom: 4px;
}

.email-form .ant-form-item-label > label {
  font-size: 14px;
}

.email-form .ant-input,
.email-form .ant-input-textarea {
  border-radius: 4px;
  border-color: #e0e0e0;
  padding: 6px 10px;
}

.email-form .ant-input:focus,
.email-form .ant-input-textarea:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.upload-section {
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  background: #585555;
  transition: all 0.3s;
}

.upload-section:hover {
  border-color: #1a73e8;
  background: #f0f7ff;
}

.upload-hint {
  margin-top: 6px;
  color: #5f6368;
  font-size: 12px;
}

.footer-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
  padding-top: 12px;
  border-top: 1px solid #e0e0e0;
}

.progress-indicator {
  margin: 12px 0;
}

.progress-indicator .ant-progress-text {
  color: #1a73e8;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .email-form .form-grid {
    grid-template-columns: 1fr;
  }

  .form-item-half {
    grid-column: 1 / -1;
  }

  .email-support-modal {
    margin: 10px;
  }

  .email-form-container {
    padding: 15px;
  }

  .email-header {
    padding-bottom: 10px;
    margin-bottom: 16px;
  }
}

.email-support-modal .ant-modal-close-x {
  color: var(--color-text-dark);
}

.footer-actions .ant-btn {
  border-radius: 20px;
}

.email-support-modal {
  width: 90%;
  max-width: 500px;
}

.text-gray-400 {
  color: #40a9ff !important;
}

.footer-actions .ant-btn-primary {
  background-color: var(--color-button-contact);
  border-color: var(--color-button-contact);
  color: var(--color-text-dark);
}

.footer-actions .ant-btn-primary:hover {
  background-color: var(--color-background-dark);
  border-color: var(--color-background-dark);
}

/* Ajustes responsivos para pantallas pequeñas */
@media (max-width: 480px) {
  .email-support-modal {
    width: auto;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-content {
    width: 100%;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .email-form-container {
    padding: 10px;
    box-shadow: none;
  }

  .email-header h2 {
    font-size: 16px;
    text-align: center;
  }

  .email-form .form-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .footer-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .footer-actions .ant-btn {
    width: 100%;
    margin-bottom: 8px;
  }

  .upload-section {
    padding: 8px;
    font-size: 14px;
  }

  .upload-hint {
    font-size: 10px;
  }
}

.ant-modal-close {
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-modal-close {
  color: var(--color-text);
}

.button-attach {
  background-color: #1890ff !important; /* Color primario */
  border: none !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none; /* Sin transición */
}

.button-attach:hover,
.button-attach:focus,
.button-attach:active {
  background-color: #1890ff !important; /* Mantener el mismo fondo */
  color: #fff !important;
}