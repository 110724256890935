.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
  }
  
  .ant-modal {
    z-index: 1000;
  }
  
  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: var(--color-background);
    color: var(--color-text);
  }
  
  .ant-modal-content {
    border-radius: 8px;
  }
  
  .ant-modal-header {
    background-color: var(--color-background);
    color: var(--color-text);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .ant-modal-footer {
    background-color: var(--color-background);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .ant-btn-primary {
    background-color: var(--color-button-primary);
    border-color: var(--color-button-primary);
    color: var(--color-text);
  }
  
  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary:active {
    background-color: var(--color-button-primary-hover);
    border-color: var(--color-button-primary-hover);
  }