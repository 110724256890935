@keyframes blink {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.animated-button {
  animation: blink 1.5s infinite;
}

.actualizations-container {
  padding: 10px 0 0 10px;
  border-radius: 30px;
  overflow: hidden;
  margin: 0;
}

.update-card {
  background-color: var(--color-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.update-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.update-header {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-date {
  font-size: 12px;
  color: var(--color-text-secondary);
}

.update-description {
  font-size: 14px;
  color: var(--color-text);
}

.update-version {
  font-size: 12px;
  color: var(--color-text-secondary);
  text-align: right;
}

.update-description h3 {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.updates-list {
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.ant-card {
  border-radius: 8px;
}

.custom-modal .ant-modal {
  border-radius: 12px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal .ant-modal-content {
  border-radius: 12px !important;
}

.custom-modal .ant-modal-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.custom-modal .ant-btn {
  color: #ffffff;
  background-color: #1890ff;
  border: none;
  border-radius: 4px;
}

.custom-modal .ant-btn:hover {
  background-color: #40a9ff;
}

.border-interface {
  border-left: 8px solid #e67e22;
}

.border-functional {
  border-left: 8px solid #3498db;
}

.border-scalability {
  border-left: 8px solid #1abc9c;
}

.border-support {
  border-left: 8px solid #e74c3c;
}

