@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  background: "#242527";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item-left {
  flex-grow: 4;
  text-align: left;
}

.flex-item-right {
  flex: 10%;
  text-align: right;
}


@media (max-width: 1440px) {
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    font-size: 0.72em; /* Ajusta el tamaño de fuente a 0.72em */
  }
}

.perfiles-container {
  padding: 20px;
}

.perfiles-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.create-profile-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-profile-btn:hover {
  background-color: #40a9ff;
}

.switch-container {
  display: flex;
  align-items: center;
}

.switch-label {
  margin-right: 10px;
}

.config-btn {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.config-btn:hover {
  background-color: #e6e6e6;
}

:root {
  --color-background: #ffffff;
  --color-text: #000000;
  --color-header: #ffffff;
  --color-footer: #ffffff;
  --color-button-filter: #B0E0E6;
  --color-button-filter-hover: #A0D0D6;
  --color-button-excel: #00FF00;
  --color-button-excel-hover: #00CC00;
  --color-button-preferences: #FFA500;
  --color-button-preferences-hover: #FF8C00;
  --color-icon: #000000;
  --scrollbar-thumb-color: #555; /* Color por defecto */
  --scrollbar-track-color: #ffffff; /* Color por defecto */
  --color-drawer-background: #ffffff; /* Fondo del drawer */
  --color-card-background: #ffffff; /* Fondo de las tarjetas */
  --color-button-background: #ffffff; /* Fondo de los botones */
  --color-border: #ffffff; /* Color de los bordes */
  /* GRAFICOS */
  --color-text-dark: #ffffff; /* Blanco */
  --color-text-chart: #f0f0f0; /* Más blanco */
  /* GRILLAS */
  --color-background-light: #ffffff;
  /* POPOVER */
  --color-popover: #ffffff; /* Color para el modo claro */
  --color-text-dark: #000000;
  --color-border-dark: #cccccc;
  --color-button-add-user: #FFA500;
  --color-button-add-user-hover: #FF8C00;
  --color-button-add-profile: #FFA500;
  --color-button-add-profile-hover: #FF8C00;
  --color-button-add-updates: #FFA500;
  --color-button-add-updates-hover: #FF8C00;
  --color-text-secondary: #666666;
  --color-primary: #007bff; /* Ajusta según el color primario deseado */
  --color-background-dark: #ffffff;
  --color-text: #333333;
  --header-bg-light: #f7f7f7;
  --header-text-light: #333;
  --header-bg-dark: #333;
  --header-text-dark: #f0f0f0;
}

:root[data-theme='light'] {
  --color-background: #ffffff;
  --color-text: #000000;
  --color-primary: #007bff;
  --color-secondary: #6c757d;
  --color-border: #e0e0e0;
  --color-button-filter: #B0E0E6;
  --color-button-filter-hover: #87CEEB;
  --color-button-excel: #3CB371;
  --color-button-excel-hover: #2E8B57;
  --color-button-preferences: #FFB347;
  --color-button-preferences-hover: #FFA07A;
  --color-icon: #000000;
  --color-button-primary: #1890ff;
  --color-button-default: #d5d5df;
}

:root[data-theme='dark'] {
  --color-background: #343a40;
  --color-text: #ffffff;
  --color-primary: #1a73e8;
  --color-secondary: #adb5bd;
  --color-border: #495057;
  --color-button-filter: #5F9EA0;
  --color-button-filter-hover: #4682B4;
  --color-button-excel: #2E8B57;
  --color-button-excel-hover: #006400;
  --color-button-preferences: #FF8C00;
  --color-button-preferences-hover: #FF4500;
  --color-icon: #000000;
  --color-button-primary: #1890ff;
  --color-button-default: #53535a;
}

[data-theme="dark"] {
  --color-background: #363543;
  --color-text: #ffffff;
  --color-text-secondary: #aeaeae;
  --color-header: #070159;
  --color-footer: #363543;
  --color-button-filter: #5f9ea0; /* Cadet blue */
  --color-button-excel: #2e8b57; /* Sea green */
  --color-button-preferences: #cd5c5c; /* Indian red */
  --color-icon: #000000;
  --color-layout: #2e2e3a;
  --color-title: #ffffff; /* Color blanco para el texto del título */
  --scrollbar-thumb-color: #888; /* Color oscuro */
  --scrollbar-track-color: var(--color-layout); /* Fondo oscuro */
  --color-drawer-background: #22222a;
  --color-card-background: #1e1e28;
  --color-button-background: #53535a;
  --color-border: #444444;
  --color-select-selector: #171717;
  --color-background-dark: #363543;
  --color-popover: #4a4950; /* Color para el modo oscuro */
  --color-text-dark: #ffffff;
  --color-border-dark: #444444;
  --color-button-contact: #1a73e8;
  --color-button-primary: #1890ff;
  --color-button-default: #53535a;
}

[data-theme="dark"] .ant-breadcrumb-link {
  color: var(--color-title) !important;
}
/* GRAFICOS */
[data-theme="dark"] .chart-title,
[data-theme="dark"] .ant-typography,
[data-theme="dark"] .ant-chart {
  color: var(--color-text-dark);
}

[data-theme="dark"] .chart-labels {
  color: var(--color-text-chart);
}

[data-theme="dark"] .ant-drawer-content,
[data-theme="dark"] .ant-card {
  background-color: var(--color-drawer-background);
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-drawer-content,
[data-theme="light"] .ant-card {
  background-color: var(--color-background);
  color: var(--color-text);
}

[data-theme="dark"] .ant-card-head-title,
[data-theme="dark"] .ant-card-body {
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-card-head-title,
[data-theme="light"] .ant-card-body {
  color: var(--color-text);
}

[data-theme="dark"] .ant-table-container {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-table-container {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

[data-theme="dark"] .ant-table-thead > tr > th {
  background-color: var(--header-bg-dark);
  color: var(--header-text-dark);
  font-weight: bold;
  border-bottom: 2px solid #666;
  border-right: 1px solid #555; /* Separador entre columnas */
  transition: none; /* Sin transiciones */
}

[data-theme="light"] .ant-table-thead > tr > th {
  background-color: var(--header-bg-light);
  color: var(--header-text-light);
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ddd; /* Separador entre columnas */
  transition: none; /* Sin transiciones */
}

[data-theme="dark"] .ant-form-item-label > label {
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-form-item-label > label {
  color: var(--color-text);
}

/* Desactivar el efecto de hover en las celdas de la tabla */
.ant-table-tbody > tr > td {
  background-color: inherit; /* Mantener el color de fondo original */
  color: inherit; /* Mantener el color de texto original */
}

.ant-table-tbody > tr:hover > td {
  background-color: inherit; /* Mantener el color de fondo original al hacer hover */
  color: inherit; /* Mantener el color de texto original al hacer hover */
}

.ant-table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  background-color: var(--color-layout);
  color: var(--color-text);
  border-radius: 8px;
  padding: 10px;
}

.ant-empty-description {
  color: var(--color-text);
}

.ant-btn.ant-btn-default {
  background-color: var(--color-button-default) !important;
  color: var(--color-text) !important;
}

.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.floating-buttons .button-filter {
  background-color: var(--color-button-filter) !important; /* Powder blue */
  color: var(--color-icon) !important; /* Negro */
}

.floating-buttons .button-excel {
  background-color: #3CB371 !important; /* Medium sea green */
  color: #000000 !important; /* Negro */
}

.floating-buttons .button-preferences {
  background-color: #FF7F50 !important; /* Coral */
  color: #000000 !important; /* Negro */
}

.floating-buttons .button-filter,
.floating-buttons .button-excel,
.floating-buttons .button-preferences {
  border: none !important;
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  cursor: pointer !important;
  transition: none !important; /* Elimina la transición de color */
}

.floating-buttons .button-filter:hover,
.floating-buttons .button-excel:hover,
.floating-buttons .button-preferences:hover {
  background-color: inherit !important; /* mantiene el mismo color */
  color: inherit !important; /* mantiene el mismo color */
}

.ant-pagination-total-text {
  color: var(--color-text-dark) !important;
} 

.ant-drawer-close {
  color: var(--color-text-dark) !important;
}

[data-theme="dark"] .ant-drawer-title {
  font-weight: bold !important; /* Aplica negrita */
  color: #ffffff !important; /* Color blanco para el modo oscuro */
}

[data-theme="light"] .ant-drawer-title {
  font-weight: bold !important; /* Aplica negrita */
  color: #000000 !important; /* Color negro para el modo claro */
}

.drawer-button {
  background-color: var(--color-button-background);
  color: var(--color-icon);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drawer-button:hover {
  background-color: var(--color-button-hover);
}

.ant-popover-inner {
  background-color: var(--color-popover);
  color: var(--color-text-dark);
  border: 1px solid var(--color-border-dark);
}

.ant-popover-inner-content {
  background-color: var(--color-popover);
  color: var(--color-text-dark);
  border: 1px solid var(--color-border-dark)
}

.ant-table-cell-row-hover {
  background-color: var(--color-layout) !important; /* Mantiene el color de fondo original */
  color: var(--color-text) !important; /* Mantiene el color de texto original */
}

.ant-checkbox-wrapper {
  color: var(--color-text-dark);
}

.floating-buttons .button-filter:hover {
  background-color: var(--color-button-filter-hover) !important;
  color: var(--color-icon) !important;
  opacity: 1 !important;
}

.floating-buttons .button-excel:hover {
  background-color: var(--color-button-excel-hover) !important;
  color: var(--color-icon) !important;
  opacity: 1 !important;
}

.floating-buttons .button-preferences:hover {
  background-color: var(--color-button-preferences-hover) !important;
  color: var(--color-icon) !important;
  opacity: 1 !important;
}

.ant-radio {
  color: var(--color-text-dark);
}

.ant-radio-wrapper{
  color: var(--color-text-dark);
}

.ant-collapse-header {
  color: var(--color-text-dark);
}

/* Estilos para el modo claro */
[data-theme="light"] .ant-collapse {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

[data-theme="light"] .ant-collapse-header {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

/* Estilos para el modo oscuro */
[data-theme="dark"] .ant-collapse {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

[data-theme="dark"] .ant-collapse-header {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

/* Estilos para el modo claro */
[data-theme="light"] .ant-collapse-content-box {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

/* Estilos para el modo oscuro */
[data-theme="dark"] .ant-collapse-content-box {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

/* Asegúrate de que el icono de la flecha también cambie de color */
[data-theme="dark"] .ant-collapse-expand-icon {
  color: var(--color-text-dark);
}

/* Estilos para el modo claro */
[data-theme="light"] .ant-modal-content {
  background-color: var(--color-background-light);
  color: var(--color-text-light);
}

/* Estilos para el modo oscuro */
[data-theme="dark"] .ant-modal-content {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

.ant-modal-header {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-modal-header {
  background-color: var(--color-background-light);
  color: var(--color-text);
}

.ant-modal-title {
  color: var(--color-text-dark);
}

.ant-modal-close {
  color: var(--color-text);
}

[data-theme="dark"] .ant-modal-close {
  color: var(--color-text-dark);
}

.ant-list-item {
  color: var(--color-text-dark);
}

[data-theme="light"] .ant-list-item {
  color: var(--color-text);
}

[data-theme="dark"] .ant-card.ant-card-small {
  border: 1px solid #ffffff; /* Borde blanco */
}

/* Estilos globales */
a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Botones */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-primary);
  color: var(--color-text);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--color-secondary);
}

.button-filter.ant-btn-primary:active {
  background-color: var(--color-button-filter-hover) !important;
}

/* Contenedores */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Tarjetas */
.card {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

[data-theme="dark"] .update-card {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark) !important;
}

[data-theme="dark"] .update-card-title,
[data-theme="dark"] .update-card-description,
[data-theme="dark"] .update-card-date {
  color: var(--color-text-dark) !important;
}

.ant-btn-primary {
  background-color: #1890ff !important;
  border: none !important;
  color: #fff !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #40a9ff !important; /* Un tono más claro para el hover */
  color: #fff !important;
}

.ant-btn-default {
  background-color: #d9d9d9 !important; /* Fondo gris */
  border: none !important;
  color: #333 !important;
}

.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active {
  background-color: #bfbfbf !important; /* Un tono más oscuro para el hover */
  color: #333 !important;
}

.icon-delete {
  background-color: #1890ff !important; /* Color primario */
  border: 1px solid #1890ff !important; /* Borde del mismo color */
  color: #fff !important; /* Texto blanco */
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  transition: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-delete:hover,
.icon-delete:focus,
.icon-delete:active {
  background-color: #40a9ff !important; /* Un tono más claro para el hover */
  border: 1px solid #40a9ff !important;
  color: #fff !important;
}

.ant-btn.button-actualizaciones-unique {
  background-color: transparent !important; /* Sin color de fondo */
  border: none !important; /* Sin borde */
  color: #1890ff !important; /* Color del texto */
  border-radius: 0 !important; /* Sin bordes redondeados */
  padding: 5px 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none !important; /* Sin transición */
}

.ant-btn.button-actualizaciones-unique:hover,
.ant-btn.button-actualizaciones-unique:focus,
.ant-btn.button-actualizaciones-unique:active {
  background-color: transparent !important; /* Mantener el mismo fondo */
  color: #1890ff !important; /* Mantener el mismo color del texto */
}

.ant-switch {
  background-color: #f0f0f0; /* Color de fondo claro */
  border: 1px solid #d9d9d9; /* Borde claro */
  border-radius: 20px; /* Bordes redondeados */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px; /* Ajusta el espacio interno */
  height: 22px; /* Ajusta la altura según sea necesario */
}

.ant-switch-checked {
  background-color: #1890ff; /* Color cuando está activado */
}

.ant-switch-handle {
  background-color: #fff; /* Color del handle */
  border: 1px solid #d9d9d9; /* Borde del handle */
  border-radius: 50%; /* Bordes redondeados para el handle */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px; /* Ajusta la altura del handle */
  width: 16px; /* Ajusta el ancho del handle */
}

/* Eliminar estilos avanzados de tablas */
.ant-table {
  font-size: 16px !important; /* Tamaño de fuente fijo */
  color: var(--color-text);
  border-collapse: collapse; /* Asegura que los bordes se unan */
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  font-size: 14px !important; /* Tamaño de fuente fijo */
  color: inherit;
  border-right: none; /* Elimina las líneas verticales */
}

.ant-table-thead > tr > th:last-child {
  border-right: none; /* Elimina el separador de la última columna */
}

.ant-table-thead > tr > th:hover {
  background-color: inherit !important; /* Elimina el estilo de hover */
  color: inherit !important;
  cursor: default;
}

.ant-drawer .ant-card {
  border: 1px solid #d9d9d9 !important; /* Borde suave de color gris claro */
  border-radius: 4px; /* Opcional: añade bordes redondeados */
}

[data-theme="dark"] .ant-card-head-title,
[data-theme="light"] .ant-card-head-title {
  font-weight: bold !important; /* Aplica negrita a los títulos de las tarjetas */
}

.ant-select-selection-item {
  color: #000 !important; /* Color negro para el texto seleccionado */
}

.ant-select-selection-placeholder {
  color: #aaa !important; /* Color gris para el texto del placeholder */
}

[data-theme="dark"] .ant-select-selection-search-input {
  color: #000000 !important; /* Color negro para el texto que se escribe */
}

[data-theme="dark"] .ant-select-selection-search {
  background-color: #ffffff !important; /* Fondo blanco para el área de búsqueda */
}

[data-theme="dark"] .ant-select-selector {
  background-color: #ffffff !important; /* Fondo blanco para todo el selector */
  color: #000000 !important; /* Color negro para el texto */
}

[data-theme="dark"] .ant-select-selection-placeholder {
  color: #666666 !important; /* Color gris oscuro para el placeholder */
}
